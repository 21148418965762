// Import Image for ImageCard_Collapse
import foto1 from '../../../assets/Images/ledelse1.png';
import foto2 from '../../../assets/Images/ledelse2.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Page Data
const Data = {
  pageName: 'VisitKort Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: null, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>', // accepts null
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'Columns',
      componentData: {
        columns: 2,
        gap: '0',
        minWidth: '762px',
      },
      componentChildren: [
        {
          componentName: 'VisitKort',
          componentData: {
            id: 1,
            image: foto1,
            name: 'Søren P. Espersen',
            job: 'Kommunikations- & HR-direktør',
            tlf: '',
            email: 'spe@sampension.dk',
          },
        },
        {
          componentName: 'VisitKort',
          componentData: {
            id: 2,
            image: foto2,
            name: 'Bente Vestergaard',
            job: 'Økonomidirektør',
            tlf: '40366150',
            email: 'spe@sampension.dk',
          },
        },
      ],
    },
    {
      componentName: 'Columns',
      componentData: {
        columns: 3,
        gap: '40',
        minWidth: '762px',
      },
      componentChildren: [
        {
          componentName: 'VisitKort',
          componentData: {
            id: 1,
            image: foto1,
            name: 'Søren P. Espersen',
            job: 'Kommunikations- & HR-direktør',
            tlf: '40366150',
            email: 'spe@sampension.dk',
          },
        },
        {
          componentName: 'VisitKort',
          componentData: {
            id: 2,
            image: foto2,
            name: 'Bente Vestergaard',
            job: 'Økonomidirektør',
            tlf: '40366150',
            email: 'spe@sampension.dk',
          },
        },
        {
          componentName: 'VisitKort',
          componentData: {
            id: 3,
            image: foto1,
            name: 'Søren P. Espersen',
            job: 'Kommunikations- & HR-direktør',
            tlf: '40366150',
            email: 'spe@sampension.dk',
          },
        },
      ],
    },
    {
      componentName: 'Columns',
      componentData: {
        columns: 3,
        gap: '40',
        minWidth: '762px',
      },
      componentChildren: [
        {
          componentName: 'VisitKort',
          componentData: {
            id: 1,
            image: '',
            name: 'Søren P. Espersen',
            job: 'Kommunikations- & HR-direktør',
            tlf: '40366150',
            email: 'spe@sampension.dk',
          },
        },
        {
          componentName: 'VisitKort',
          componentData: {
            id: 2,
            image: '',
            name: 'Bente Vestergaard',
            job: 'Økonomidirektør',
            tlf: '40366150',
            email: 'spe@sampension.dk',
          },
        },
        {
          componentName: 'VisitKort',
          componentData: {
            id: 3,
            image: '',
            name: 'Søren P. Espersen',
            job: 'Kommunikations- & HR-direktør',
            tlf: '40366150',
            email: 'spe@sampension.dk',
          },
        },
      ],
    },
  ],
};

export default Data;
